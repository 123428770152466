/* Use lowercase because Sails converts all header keys to lowercase */
export const TRACE_ID_HEADER = 'x-trace-id';
export const SOURCE_HEADER = 'x-source';
export const SSR_USER_AGENT_AXIOS = 'quotebeam/axios';
export const SSR_USER_AGENT_FETCH = 'quotebeam/fetch';
export const USER_AGENT_HEADER = 'user-agent';

type RequestLibrary = 'fetch' | 'axios';

type StandardHeaders = {
  [USER_AGENT_HEADER]?: string;
  [SOURCE_HEADER]: string;
  [TRACE_ID_HEADER]: string;
};

/**
 * Gets the User-Agent header for server-side requests
 */
const getUserAgentHeader = (library: RequestLibrary) => {
  if (isBrowser) {
    // Don't set user agent on client, because browsers browser block such requests
    return null;
  }

  return {
    [USER_AGENT_HEADER]:
      library === 'axios' ? SSR_USER_AGENT_AXIOS : SSR_USER_AGENT_FETCH,
  };
};

/**
 * Gets the x-source header to identify request origin
 */
const getXSourceHeader = () => {
  const currentAppName = process.env.CURRENT_APP_NAME || 'unknown';
  const env = isServer ? 'server' : 'client';

  return {
    [SOURCE_HEADER]: `${env}/${currentAppName}`,
  };
};

/**
 * Gets a unique trace ID header for request tracking
 */
const getXTraceIdHeader = () => {
  if (typeof crypto === 'undefined' || !crypto.randomUUID) {
    return {
      [TRACE_ID_HEADER]: `qb-${Math.random().toString(36).substring(2, 20)}`,
    };
  }

  return { [TRACE_ID_HEADER]: `qb-${crypto.randomUUID()}` };
};

/**
 * Combines all standard headers for HTTP requests
 */
export const getStandardHeaders = (
  library: RequestLibrary,
): StandardHeaders => {
  const headers = {
    ...getUserAgentHeader(library),
    ...getXSourceHeader(),
    ...getXTraceIdHeader(),
  };

  return headers;
};

export const isServer = typeof window === 'undefined';
export const isBrowser = !isServer;
