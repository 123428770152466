import { forwardRef } from 'react';
import { Inter } from 'next/font/google';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { PaletteOptions } from '@mui/material';
import { createBaseThemeOptions } from '@qb/base-theme/base-theme';
import { addSharpCornersStyle, integrateThemeWithRouter, mergeCssVarsThemeOptions } from '@qb/base-theme/base-theme-utils';
import BlockedOnDemoLink from '@qb/frontend/components/BlockedOnDemoLink';
import { getConfigFromTenantAlias, TenantAlias } from '@/shared/tenants-alias-map';
const isBlockedURLInDemo = (href: string) => href === '/customers' || href === '/certificates' || href.startsWith('/settings') || href.startsWith('/inventory-and-pricing');
const BlockedOnDemoLinkBehaviour = forwardRef<HTMLAnchorElement, NextLinkProps>(function LinkBehaviour(props, ref) {
  const hrefString = typeof props.href === 'string' ? props.href : props.href.pathname;
  return <BlockedOnDemoLink shouldShowBlockedTooltip={isBlockedURLInDemo(hrefString ?? '')} ref={ref} {...props} />;
});
const DefaultLinkBehaviour = forwardRef<HTMLAnchorElement, NextLinkProps>(function LinkBehaviour(props, ref) {
  return <NextLink ref={ref} {...props} />;
});
const inter = Inter({
  subsets: ['latin']
});
const fontFamily = `${inter.style.fontFamily},Helvetica Neue,Helvetica,Arial,sans-serif`;
const tenantPalette: Record<TenantAlias, Partial<PaletteOptions>> = {
  advantageelectricsupply: {
    spaNavbar: {
      navbarColor: '#2270E0',
      // same as primary.main for now
      secondaryNavbarMainColor: '#2270E0',
      // same as primary.main for now
      secondaryNavbarSecondaryColor: '#103770' // same as primary.dark for now
    }
  },
  appliedautomation: {
    spaNavbar: {
      navbarColor: '#CB090D',
      secondaryNavbarMainColor: '#CB090D',
      secondaryNavbarSecondaryColor: '#7A0104'
    }
  },
  automationguru: {
    spaNavbar: {
      navbarColor: '#0255D8',
      secondaryNavbarMainColor: '#0255D8',
      secondaryNavbarSecondaryColor: '#002C72'
    }
  },
  controlsforautomation: {
    spaNavbar: {
      navbarColor: '#FEFBF7',
      secondaryNavbarMainColor: '#FEFBF7',
      secondaryNavbarSecondaryColor: '#FEF6F0'
    }
  },
  demo: {
    spaNavbar: {
      navbarColor: '#0836C1',
      secondaryNavbarMainColor: '#0836C1',
      secondaryNavbarSecondaryColor: '#001C70'
    }
  },
  mechatronicsolutions: {
    spaNavbar: {
      navbarColor: '#1C3E78',
      secondaryNavbarMainColor: '#1C3E78',
      secondaryNavbarSecondaryColor: '#142F56'
    }
  },
  mqautomation: {
    spaNavbar: {
      navbarColor: '#0061A1',
      secondaryNavbarMainColor: '#0061A1',
      secondaryNavbarSecondaryColor: '#008CE8'
    }
  },
  northwestmotion: {
    spaNavbar: {
      navbarColor: '#FDCE00',
      secondaryNavbarMainColor: '#FDCE00',
      secondaryNavbarSecondaryColor: '#CA8402'
    }
  },
  powermatic: {
    spaNavbar: {
      navbarColor: '#133D6D',
      secondaryNavbarMainColor: '#133D6D',
      secondaryNavbarSecondaryColor: '#0F3157'
    }
  },
  quotebeam: {
    spaNavbar: {
      navbarColor: '#1100CC',
      secondaryNavbarMainColor: '#1100CC',
      secondaryNavbarSecondaryColor: '#0E00A3'
    }
  },
  test: {
    spaNavbar: {
      navbarColor: '#0836C1',
      secondaryNavbarMainColor: '#0836C1',
      secondaryNavbarSecondaryColor: '#001C70'
    }
  },
  // Prospects December
  avranindustrial: {
    spaNavbar: {
      navbarColor: '#2270E0',
      secondaryNavbarMainColor: '#2270E0',
      secondaryNavbarSecondaryColor: '#003F99'
    }
  },
  iriselectronics: {
    spaNavbar: {
      navbarColor: '#CB090D',
      secondaryNavbarMainColor: '#CB090D',
      secondaryNavbarSecondaryColor: '#7A0104'
    }
  },
  mancocontrols: {
    spaNavbar: {
      navbarColor: '#133D6D',
      secondaryNavbarMainColor: '#133D6D',
      secondaryNavbarSecondaryColor: '#003F99'
    }
  },
  priinc: {
    spaNavbar: {
      navbarColor: '#FDCE00',
      secondaryNavbarMainColor: '#FDCE00',
      secondaryNavbarSecondaryColor: '#CA8402'
    }
  },
  sensorsite: {
    spaNavbar: {
      navbarColor: '#CB090D',
      secondaryNavbarMainColor: '#CB090D',
      secondaryNavbarSecondaryColor: '#7A0104'
    }
  },
  superiorelectricmotors: {
    spaNavbar: {
      navbarColor: '#CB090D',
      secondaryNavbarMainColor: '#CB090D',
      secondaryNavbarSecondaryColor: '#7A0104'
    }
  },
  // Prospects January Week 1
  bdindustrial: {
    spaNavbar: {
      navbarColor: '#F6D80B',
      secondaryNavbarMainColor: '#F6D80B',
      secondaryNavbarSecondaryColor: '#A79200'
    }
  },
  controlplus: {
    spaNavbar: {
      navbarColor: '#2270E0',
      secondaryNavbarMainColor: '#2270E0',
      secondaryNavbarSecondaryColor: '#003F99'
    }
  },
  proautomation: {
    spaNavbar: {
      navbarColor: '#2270E0',
      secondaryNavbarMainColor: '#2270E0',
      secondaryNavbarSecondaryColor: '#010064'
    }
  },
  proindustrialcontrol: {
    spaNavbar: {
      navbarColor: '#133D6D',
      secondaryNavbarMainColor: '#133D6D',
      secondaryNavbarSecondaryColor: '#003F99'
    }
  },
  saddlebrookcontrols: {
    spaNavbar: {
      navbarColor: '#FDCE00',
      secondaryNavbarMainColor: '#FDCE00',
      secondaryNavbarSecondaryColor: '#CA8402'
    }
  },
  // Prospects January Week 2
  aaelectricmotors: {
    spaNavbar: {
      navbarColor: '#0836C1',
      secondaryNavbarMainColor: '#0836C1',
      secondaryNavbarSecondaryColor: '#001C70'
    }
  },
  bodinedecatur: {
    spaNavbar: {
      navbarColor: '#0836C1',
      secondaryNavbarMainColor: '#0836C1',
      secondaryNavbarSecondaryColor: '#001C70'
    }
  },
  adconengineering: {
    spaNavbar: {
      navbarColor: '#0836C1',
      secondaryNavbarMainColor: '#0836C1',
      secondaryNavbarSecondaryColor: '#001C70'
    }
  },
  'ac-dc': {
    spaNavbar: {
      navbarColor: '#0836C1',
      secondaryNavbarMainColor: '#0836C1',
      secondaryNavbarSecondaryColor: '#001C70'
    }
  },
  bestwayelectric: {
    spaNavbar: {
      navbarColor: '#0836C1',
      secondaryNavbarMainColor: '#0836C1',
      secondaryNavbarSecondaryColor: '#001C70'
    }
  },
  // Prospects January Week 3
  foremostindustrialtechnologies: {
    spaNavbar: {
      navbarColor: '#0836C1',
      secondaryNavbarMainColor: '#0836C1',
      secondaryNavbarSecondaryColor: '#001C70'
    }
  },
  dptechnologies: {
    spaNavbar: {
      navbarColor: '#0836C1',
      secondaryNavbarMainColor: '#0836C1',
      secondaryNavbarSecondaryColor: '#001C70'
    }
  },
  crossautomation: {
    spaNavbar: {
      navbarColor: '#0836C1',
      secondaryNavbarMainColor: '#0836C1',
      secondaryNavbarSecondaryColor: '#001C70'
    }
  },
  controlsource: {
    spaNavbar: {
      navbarColor: '#0836C1',
      secondaryNavbarMainColor: '#0836C1',
      secondaryNavbarSecondaryColor: '#001C70'
    }
  },
  controldesignsupply: {
    spaNavbar: {
      navbarColor: '#0836C1',
      secondaryNavbarMainColor: '#0836C1',
      secondaryNavbarSecondaryColor: '#001C70'
    }
  }
};
const getBaseTheme = (tenantAlias: TenantAlias) => createBaseThemeOptions(tenantAlias ? tenantPalette[tenantAlias] : {}, fontFamily);
export const spaThemeOptions = (tenantAlias: TenantAlias) => {
  const config = getConfigFromTenantAlias(tenantAlias);
  const isDemo = config?.isDemo;
  return mergeCssVarsThemeOptions(getBaseTheme(tenantAlias), integrateThemeWithRouter(isDemo ? BlockedOnDemoLinkBehaviour : DefaultLinkBehaviour), addSharpCornersStyle, {
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true
        },
        styleOverrides: {
          outlined: {
            backgroundColor: 'var(--mui-palette-common-white)'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: 'var(--mui-palette-common-white)'
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: 'var(--mui-palette-background-paper)'
          }
        }
      },
      MuiTableCell: {
        defaultProps: {},
        styleOverrides: {
          head: {
            color: 'var(--mui-palette-tertiary-contrastText)',
            backgroundColor: 'var(--mui-palette-tertiary-dark)',
            lineHeight: 1.5,
            textTransform: 'unset',
            '&:first-of-type': {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            },
            '&:last-of-type': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            }
          }
        }
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: 'xl'
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          invisible: {
            background: 'unset'
          },
          root: {
            background: 'rgba(141, 154, 171, 0.5)'
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          colorAccent: {
            color: 'var(--mui-palette-text-primary)'
          }
        },
        defaultProps: {
          size: 'small'
        }
      }
    }
  });
};