import { LoadingButton } from '@mui/lab';
import { Box, Typography, TextField, Button, Alert, Grid2, Stack, Card } from '@mui/material';
import { formatAddressMultiLine } from '@qb/frontend/utils/formatAddress';
import { ContactFindOneByEmailResponse } from '@/shared/types/controllers/spa/SPAContactControllerTypes';
import { CustomerEnrichmentLine } from '@/src/components/CustomerEnrichment/components/CustomerEnrichmentLine';
type SearchContactProps = {
  onContinue: () => void;
  onCancel: () => void;
  isFetching: boolean;
  query: string;
  setQuery: (query: string) => void;
  existingContactInfo?: ContactFindOneByEmailResponse['existingContactInfo'];
  isQueryValidEmail: boolean;
};
export const ContactEmailInput = ({
  isFetching,
  query,
  setQuery,
  existingContactInfo,
  onContinue,
  onCancel,
  isQueryValidEmail
}: SearchContactProps) => {
  return <>
      <Grid2 size={{
      xs: 12,
      md: 6
    }} data-sentry-element="Grid2" data-sentry-source-file="ContactEmailInput.tsx">
        <Typography variant="h4" component="h1" sx={{
        mb: 2
      }} data-sentry-element="Typography" data-sentry-source-file="ContactEmailInput.tsx">
          Add New Customer
        </Typography>
        <Box sx={{
        mt: 1
      }} data-sentry-element="Box" data-sentry-source-file="ContactEmailInput.tsx">
          <TextField fullWidth size="small" id="email" label="Email address" name="email" autoFocus value={query} onChange={e => setQuery(e.target.value)} data-sentry-element="TextField" data-sentry-source-file="ContactEmailInput.tsx" />
          <Box sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'space-between'
        }} data-sentry-element="Box" data-sentry-source-file="ContactEmailInput.tsx">
            <Button variant="outlined" onClick={onCancel} size="large" sx={{
            width: '48%'
          }} data-sentry-element="Button" data-sentry-source-file="ContactEmailInput.tsx">
              Cancel
            </Button>
            <LoadingButton variant="contained" sx={{
            width: '48%'
          }} disabled={Boolean(existingContactInfo) || !isQueryValidEmail} loading={isFetching} size="large" onClick={onContinue} data-sentry-element="LoadingButton" data-sentry-source-file="ContactEmailInput.tsx">
              Continue
            </LoadingButton>
          </Box>
        </Box>
      </Grid2>
      <Grid2 size={{
      xs: 12,
      md: 6
    }} data-sentry-element="Grid2" data-sentry-source-file="ContactEmailInput.tsx">
        {existingContactInfo && <>
            <Alert severity="info" sx={{
          mt: 5
        }}>
              This User already exist
            </Alert>
            <Card sx={{
          p: 2
        }}>
              <Stack direction="row" sx={{
            justifyContent: 'space-between'
          }}>
                <Typography variant="h6" sx={{
              color: 'text.secondary'
            }}>
                  {existingContactInfo.contact.firstName}{' '}
                  {existingContactInfo.contact.lastName}
                </Typography>

                <Stack spacing={1}>
                  {existingContactInfo.phone && <CustomerEnrichmentLine text={`Phone number: ${existingContactInfo.phone}`} source={existingContactInfo.phoneSource} />}
                  {existingContactInfo.jobTitle && <CustomerEnrichmentLine text={`Job title: ${existingContactInfo.jobTitle}`} source={existingContactInfo.jobTitleSource} />}
                </Stack>
              </Stack>
              {existingContactInfo.address && <Box sx={[{
            mt: 5
          }, theme => ({
            span: theme.typography.bodySmall
          })]}>
                  <Typography variant="h6" sx={{
              color: 'text.secondary'
            }}>
                    {existingContactInfo.contact.companyName}
                  </Typography>
                  {formatAddressMultiLine(existingContactInfo.address)}
                </Box>}
            </Card>
          </>}
      </Grid2>
    </>;
};