import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { NumberInput } from '@qb/frontend/components/NumberInput/NumberInput';
const customPartSchema = z.object({
  partNumber: z.string().min(1, 'Please enter a part number'),
  manufacturer: z.string().optional(),
  description: z.string().min(1, 'Please add a description'),
  qty: z.number().int().min(1, 'Please enter at least 1 item')
});
export type CustomPartFormData = z.infer<typeof customPartSchema>;
type CustomPartFormProps = {
  onSubmit: (data: CustomPartFormData) => void;
  isLoading?: boolean;
};
export const CustomPartForm = ({
  onSubmit,
  isLoading
}: CustomPartFormProps) => {
  const {
    register,
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<CustomPartFormData>({
    resolver: zodResolver(customPartSchema),
    defaultValues: {
      qty: 1
    }
  });
  return <form onSubmit={handleSubmit(onSubmit)} data-sentry-component="CustomPartForm" data-sentry-source-file="CustomPartForm.tsx">
      <Stack spacing={2} data-sentry-element="Stack" data-sentry-source-file="CustomPartForm.tsx">
        <Stack direction="row" spacing={4} sx={{
        alignItems: 'center'
      }} data-sentry-element="Stack" data-sentry-source-file="CustomPartForm.tsx">
          <Controller name="qty" control={control} render={({
          field: {
            onChange,
            value,
            ref
          }
        }) => <NumberInput ref={ref} initialValue={value} onValueChange={onChange} minValue={1} maxValue={9999} />} data-sentry-element="Controller" data-sentry-source-file="CustomPartForm.tsx" />
          <LoadingButton loading={isLoading} variant="contained" color="secondary" type="submit" fullWidth data-sentry-element="LoadingButton" data-sentry-source-file="CustomPartForm.tsx">
            Add to quote
          </LoadingButton>
        </Stack>
        <TextField fullWidth placeholder="Product Name / Part Number" {...register('partNumber')} error={!!errors.partNumber} helperText={errors.partNumber?.message} data-sentry-element="TextField" data-sentry-source-file="CustomPartForm.tsx" />
        <TextField fullWidth placeholder="Manufacturer (Optional)" {...register('manufacturer')} data-sentry-element="TextField" data-sentry-source-file="CustomPartForm.tsx" />
        <TextField fullWidth placeholder="Description" multiline rows={5} {...register('description')} error={!!errors.description} helperText={errors.description?.message} data-sentry-element="TextField" data-sentry-source-file="CustomPartForm.tsx" />
      </Stack>
    </form>;
};