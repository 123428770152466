import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useList } from 'react-use';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Box, Button, Grid2, Stack } from '@mui/material';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { ContactAutocompleteResponse } from '@/shared/types/controllers/spa/SPASearchControllerTypes';
import { PartToAdd } from '@/src/components/Common/PartToAddCardContent';
import { BreadcrumbButton, StandardBreadcrumb } from '@/src/components/Common/StandardBreadcrumb';
import { StandardDrawerLayout } from '@/src/components/Common/StandardDrawerLayout/StandardDrawerLayout';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { CustomerDetails } from './CustomerDetails';
import { CustomerInfoCard } from './CustomerInfoCard';
import { RequestedParts } from './RequestedParts';
export type Part = PartToAdd;
export type PartWithQty = Part & {
  qty: number;
  isCustomPartRevision?: boolean;
};
type Step = 'customerDetails' | 'requestedParts';
type CreateNewQuoteDrawerProps = {
  initialSelectedContact?: ContactAutocompleteResponse['contacts'][0] | null;
};
export const CreateNewQuoteDrawer = ({
  initialSelectedContact = null
}: CreateNewQuoteDrawerProps) => {
  const initialStep = initialSelectedContact ? 'requestedParts' : 'customerDetails';
  const [step, setStep] = useState<Step>(initialStep);
  const [selectedContact, setSelectedContact] = useState<ContactAutocompleteResponse['contacts'][0] | null>(initialSelectedContact);
  const [selectedParts, selectedPartsListActions] = useList<PartWithQty>([]);
  const {
    hideDrawer
  } = useRightDrawerContext();
  const queryClient = useQueryClient();
  const tenantAlias = useTenantAlias();
  const {
    mutate: createQuoteForContact,
    isLoading: isCreateQuoteForContactLoading
  } = useMutation({
    ...spaQueries.quote.createForContact(tenantAlias),
    onSuccess: () => {
      hideDrawer();
      toast.success('Quote created successfully');
      queryClient.invalidateQueries(spaQueries.dashboard.queryKey);
      queryClient.invalidateQueries(spaQueries.quote.queryKey);
    }
  });
  const onCreateQuoteClick = useCallback(() => {
    if (!selectedContact || selectedParts.length === 0) {
      return;
    }
    const itemQtyPairs = selectedParts.map(({
      id,
      qty,
      isCustomPartRevision
    }) => {
      if (isCustomPartRevision) {
        return {
          customPartRevision: id,
          qty,
          price: undefined
        };
      }
      return {
        part: id,
        qty
      };
    });
    createQuoteForContact({
      contactID: selectedContact.id,
      itemQtyPairs
    });
  }, [createQuoteForContact, selectedContact, selectedParts]);
  const isContinueDisabled = !selectedContact;
  const isCreateQuoteDisabled = !selectedContact || selectedParts.length === 0 || isCreateQuoteForContactLoading;
  const createQuoteButton = <Button variant="contained" onClick={onCreateQuoteClick} disabled={isCreateQuoteDisabled}>
      Create new Quote
    </Button>;
  return <StandardDrawerLayout title="Create a Quote" breadcrumbs={<StandardBreadcrumb endButton={createQuoteButton}>
          <BreadcrumbButton isActive={step === 'customerDetails'} onClick={() => setStep('customerDetails')}>
            Customer details
          </BreadcrumbButton>
          <BreadcrumbButton isActive={step === 'requestedParts'} onClick={() => setStep('requestedParts')} disabled={!selectedContact}>
            Requested parts
          </BreadcrumbButton>
        </StandardBreadcrumb>} data-sentry-element="StandardDrawerLayout" data-sentry-component="CreateNewQuoteDrawer" data-sentry-source-file="CreateNewQuoteDrawer.tsx">
      {selectedContact && <Box sx={{
      mb: 4
    }}>
          <CustomerInfoCard contact={selectedContact} />
        </Box>}
      <Grid2 container spacing={4} data-sentry-element="Grid2" data-sentry-source-file="CreateNewQuoteDrawer.tsx">
        {step === 'customerDetails' ? <CustomerDetails selectedContact={selectedContact} setSelectedContact={contact => {
        setSelectedContact(contact);
        if (contact) {
          setStep('requestedParts');
        }
      }} /> : <RequestedParts selectedContact={selectedContact} selectedParts={selectedParts} selectedPartsListActions={selectedPartsListActions} />}
      </Grid2>
      <Stack direction="row" spacing={1} sx={{
      mt: 'auto',
      ml: 'auto',
      py: 5
    }} data-sentry-element="Stack" data-sentry-source-file="CreateNewQuoteDrawer.tsx">
        {step === 'customerDetails' ? <>
            <Button variant="outlined" onClick={hideDrawer} sx={{
          width: 200
        }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => setStep('requestedParts')} disabled={isContinueDisabled} sx={{
          width: 200
        }}>
              Continue
            </Button>
          </> : <>
            <Button variant="outlined" onClick={() => setStep('customerDetails')} sx={{
          width: 200
        }}>
              Back
            </Button>
            {createQuoteButton}
          </>}
      </Stack>
    </StandardDrawerLayout>;
};