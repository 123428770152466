'use client';

import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { Button as MuiButton, Stack, Typography, Grid2, Box, ButtonProps } from '@mui/material';
import error500SadFace from './assets/error-500-sad-face.svg';
import error500 from './assets/error-500.svg';
const Button = ({
  children,
  ...rest
}: ButtonProps) => <MuiButton {...rest} variant="outlined" color="primary" sx={{
  borderColor: 'common.white',
  '&:hover': {
    color: 'common.white',
    borderColor: 'common.white'
  }
}} data-sentry-element="MuiButton" data-sentry-component="Button" data-sentry-source-file="ServerErrorPage.tsx">
    {children}
  </MuiButton>;
export const ServerErrorPage = () => {
  const router = useRouter();
  return <Grid2 container data-sentry-element="Grid2" data-sentry-component="ServerErrorPage" data-sentry-source-file="ServerErrorPage.tsx">
      <Grid2 sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'common.white'
    }} size={{
      xs: 12,
      md: 4,
      lg: 5,
      xl: 6
    }} data-sentry-element="Grid2" data-sentry-source-file="ServerErrorPage.tsx">
        <Box sx={{
        width: [300, 300, 500],
        position: 'relative',
        aspectRatio: '1/1'
      }} data-sentry-element="Box" data-sentry-source-file="ServerErrorPage.tsx">
          <Image alt="Error 500" src={error500} fill style={{
          objectFit: 'contain'
        }} data-sentry-element="Image" data-sentry-source-file="ServerErrorPage.tsx" />
        </Box>
      </Grid2>
      <Grid2 sx={{
      backgroundColor: 'primary.dark',
      backgroundImage: `url(${error500SadFace})`,
      backgroundPosition: 'right top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '25%',
      color: 'common.white',
      p: [3, 8]
    }} size={{
      xs: 12,
      md: 8,
      lg: 7,
      xl: 6
    }} data-sentry-element="Grid2" data-sentry-source-file="ServerErrorPage.tsx">
        <Typography variant="h2" color="inherit" sx={{
        mb: 7,
        fontSize: {
          xs: 26,
          lg: 36
        }
      }} data-sentry-element="Typography" data-sentry-source-file="ServerErrorPage.tsx">
          The server is experiencing a meltdown because someone tried to divide
          by zero.
        </Typography>
        <Typography variant="h3" color="inherit" sx={{
        mb: 2,
        fontWeight: 300,
        fontSize: {
          xs: 20,
          lg: 26
        }
      }} data-sentry-element="Typography" data-sentry-source-file="ServerErrorPage.tsx">
          Please refrain from mathematically challenging the server’s fragile
          ego.
        </Typography>
        <Typography variant="h3" color="inherit" sx={{
        fontWeight: 300,
        fontSize: {
          xs: 20,
          lg: 26
        }
      }} data-sentry-element="Typography" data-sentry-source-file="ServerErrorPage.tsx">
          Instead, try sending it some virtual hugs and chocolate cookies. That
          usually helps.
        </Typography>

        <Stack direction="column" sx={{
        gap: 2.5,
        width: 330,
        mt: 8
      }} data-sentry-element="Stack" data-sentry-source-file="ServerErrorPage.tsx">
          <Button onClick={() => router.refresh()} data-sentry-element="Button" data-sentry-source-file="ServerErrorPage.tsx">
            Refresh the Page (sometimes helps)
          </Button>
          <Button onClick={() => window?.location.replace('/')} data-sentry-element="Button" data-sentry-source-file="ServerErrorPage.tsx">
            Take me back home
          </Button>
        </Stack>
      </Grid2>
    </Grid2>;
};