import { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Grid2 } from '@mui/material';
import { useDebouncedQuery } from '@qb/frontend/hooks/useDebouncedQuery';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { ContactCreateForCompanyResponse, ContactCreateResponse } from '@/shared/types/controllers/spa/SPAContactControllerTypes';
import { AddCustomerAndCompanyForm } from '@/src/components/CreateNewCustomerDrawer/AddCustomerAndCompanyForm';
import { ContactEmailInput } from '@/src/components/CreateNewCustomerDrawer/ContactEmailInput';
import { NewCustomerAdded } from '@/src/components/CreateNewCustomerDrawer/NewCustomerAdded';
import { CreateNewQuoteDrawer } from '@/src/components/Dashboards/components/CreateNewQuoteDrawer/CreateNewQuoteDrawer';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { EMAIL_REGEX } from '@/src/utils/Constants';
import { StandardDrawerLayout } from '../Common/StandardDrawerLayout/StandardDrawerLayout';
type Step = 'contactEmail' | 'contactAndCompanyForm' | 'contactCreated';
type CreateNewCustomerDrawerProps = {
  returnToCreateQuote?: boolean;
  initialEmail?: string;
};
type ContactCreated = ContactCreateResponse['contact'] | ContactCreateForCompanyResponse['contact'];
export const CreateNewCustomerDrawer = ({
  returnToCreateQuote,
  initialEmail
}: CreateNewCustomerDrawerProps) => {
  const tenantAlias = useTenantAlias();
  const {
    hideDrawer,
    setActiveDrawer
  } = useRightDrawerContext();
  const queryClient = useQueryClient();
  const [step, setStep] = useState<Step>('contactEmail');
  const [contactCreated, setContactCreated] = useState<ContactCreated | null>(null);
  const {
    query,
    setQuery,
    debouncedQuery
  } = useDebouncedQuery(initialEmail);
  const isQueryValidEmail = debouncedQuery.length > 0 && isValidEmail(debouncedQuery);
  const {
    data,
    isFetching
  } = useQuery({
    ...spaQueries.contact.findOneByEmail(tenantAlias, {
      email: debouncedQuery
    }),
    enabled: isQueryValidEmail
  });
  const onError = () => {
    toast.error('There was an error creating the contact');
  };
  const onSuccess = (data: ContactCreateResponse | ContactCreateForCompanyResponse) => {
    // If we are returning to create a quote, we want to skip the confirmation screen
    if (returnToCreateQuote) {
      setActiveDrawer(<CreateNewQuoteDrawer initialSelectedContact={data.contact} />);
      return;
    }
    setContactCreated(data.contact);
    setStep('contactCreated');
    queryClient.invalidateQueries(spaQueries.contact.listContacts(tenantAlias).queryKey.filter(Boolean));
  };
  const {
    mutate: createContact,
    isLoading: isCreateContactLoading
  } = useMutation({
    ...spaQueries.contact.createContact(tenantAlias),
    onSuccess,
    onError
  });
  const {
    mutate: createContactForCompany,
    isLoading: isCreateForCompanyLoading
  } = useMutation({
    ...spaQueries.contact.createForCompany(tenantAlias),
    onSuccess,
    onError
  });
  const onClose = () => {
    if (returnToCreateQuote) {
      setActiveDrawer(<CreateNewQuoteDrawer initialSelectedContact={contactCreated} />);
      return;
    }
    hideDrawer();
  };
  if (contactCreated && step === 'contactCreated') {
    return <NewCustomerAdded contact={contactCreated} onClose={onClose} />;
  }
  return <StandardDrawerLayout title="Add New Customer" data-sentry-element="StandardDrawerLayout" data-sentry-component="CreateNewCustomerDrawer" data-sentry-source-file="CreateNewCustomerDrawer.tsx">
      <Grid2 container spacing={4} sx={{
      pb: 5,
      maxWidth: 1200
    }} data-sentry-element="Grid2" data-sentry-source-file="CreateNewCustomerDrawer.tsx">
        {step === 'contactEmail' ? <ContactEmailInput onContinue={() => setStep('contactAndCompanyForm')} onCancel={onClose} existingContactInfo={data?.existingContactInfo} isFetching={isFetching} query={query} setQuery={setQuery} isQueryValidEmail={isQueryValidEmail} /> : <AddCustomerAndCompanyForm onSubmit={data => {
        if (data.companyID === 'new') {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const {
            companyID,
            ...dataWithoutCompanyID
          } = data;
          createContact(dataWithoutCompanyID);
        } else {
          createContactForCompany({
            ...data,
            companyID: Number(data.companyID)
          });
        }
      }} onCancel={onClose} sameDomainCompanies={data?.sameDomainCompanies} enrichment={data?.enrichment} email={debouncedQuery} isLoading={isCreateContactLoading || isCreateForCompanyLoading} />}
      </Grid2>
    </StandardDrawerLayout>;
};
const isValidEmail = (email: string): boolean => {
  return EMAIL_REGEX.test(email);
};