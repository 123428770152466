// General
export const ENV_NAME = process.env.ENV_NAME;
export const BUILT_AT = process.env.BUILT_AT;
export const BUILD_ID = process.env.BUILD_ID;

// HOSTS
export const API_HOST = process.env.API_HOST;
export const WEB_HOST = process.env.WEB_HOST;

// API
export const HUBSPOT_ACCESS_TOKEN = process.env.HUBSPOT_ACCESS_TOKEN;
export const STRIPE_PUBLISHABLE_KEY = process.env.STRIPE_PUBLISHABLE_KEY || '';

// Application
export const GOOGLE_TAG_MANAGER_ID = process.env.GOOGLE_TAG_MANAGER_ID;
export const ODOT_ACCESORY_PRESELECTION_MAP = process.env
  .ODOT_ACCESORY_PRESELECTION_MAP
  ? JSON.parse(process.env.ODOT_ACCESORY_PRESELECTION_MAP)
  : null;

// S3
export const AWS_S3_PUBLIC_BUCKET = process.env.AWS_S3_PUBLIC_BUCKET;
