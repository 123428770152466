/**
 * Get the S3 file URL
 * @param bucket @example 'appstoragepublicprod'
 * @param pathPrefix @example 'Sitemap-Powermatic'
 * @param fileName @example 'sitemap_index.xml'
 */
export const getS3FileUrl = (
  bucket: string,
  pathPrefix: string,
  fileName: string,
) => {
  return `https://${bucket}.s3.us-west-1.amazonaws.com/${pathPrefix}/${fileName}`;
};
