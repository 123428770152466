'use client';

import { useState, FormEventHandler, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Link as MuiLink, TextField, Typography } from '@mui/material';
import { recordEvent } from '@qb/frontend/analytics/ga4';
import { ForgotPasswordView } from '@/src/components/Auth/ForgotPasswordView/ForgotPasswordView';
import { useLoginRequest } from '@/src/components/Auth/hooks/useLoginRequest';
import { LinkOnClickOrRedirect } from '@/src/components/Common/LinkOnClickOrRedirect';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
type LoginFormProps = {
  shouldRedirect?: boolean;
  initialEmail?: string | null;
  headerTextsType?: string;
};
export const LoginForm = ({
  initialEmail = '',
  shouldRedirect = false
}: LoginFormProps) => {
  const [email, setEmail] = useState(initialEmail || '');
  const [password, setPassword] = useState('');
  const [isSending, setIsSending] = useState(false);
  const {
    setActiveDrawer
  } = useRightDrawerContext();
  const {
    submitLoginRequest,
    errorMessage
  } = useLoginRequest();
  useEffect(() => {
    recordEvent('view_login_form');
  }, []);
  const handleSubmit: FormEventHandler = async event => {
    event.preventDefault();
    if (isSending) {
      return;
    }
    setIsSending(true);
    await submitLoginRequest({
      email,
      password
    });
    setIsSending(false);
  };
  return <Box id="login-form" component="form" onSubmit={handleSubmit} data-sentry-element="Box" data-sentry-component="LoginForm" data-sentry-source-file="LoginForm.tsx">
      {errorMessage && <Alert>{errorMessage}</Alert>}
      <Box sx={{
      width: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: {
        xs: 2,
        md: 3
      }
    }} data-sentry-element="Box" data-sentry-source-file="LoginForm.tsx">
        <TextField required fullWidth type="text" name="email" label="Email address" value={email} onChange={event => setEmail(event.target.value)} autoFocus={Boolean(!initialEmail)} data-sentry-element="TextField" data-sentry-source-file="LoginForm.tsx" />
        <TextField required fullWidth type="password" name="password" label="Password" value={password} onChange={event => setPassword(event.target.value)} autoFocus={Boolean(initialEmail)} data-sentry-element="TextField" data-sentry-source-file="LoginForm.tsx" />
        <LoadingButton type="submit" loading={isSending} color="primary" fullWidth variant="contained" size="large" data-sentry-element="LoadingButton" data-sentry-source-file="LoginForm.tsx">
          Log in
        </LoadingButton>
      </Box>
      <Box sx={{
      justifySelf: 'start',
      display: 'flex',
      mt: 2
    }} data-sentry-element="Box" data-sentry-source-file="LoginForm.tsx">
        <Typography component="span" variant="bodyMediumPrimary" sx={{
        mr: 0.5
      }} data-sentry-element="Typography" data-sentry-source-file="LoginForm.tsx">
          Forgot your password?
        </Typography>
        <MuiLink href={shouldRedirect ? '/forgotPassword' : ''} onClick={() => setActiveDrawer(<ForgotPasswordView viewType="drawer" />)} component={LinkOnClickOrRedirect} shouldRedirect={shouldRedirect} variant="bodyMedium" underline="always" data-sentry-element="MuiLink" data-sentry-source-file="LoginForm.tsx">
          Reset it
        </MuiLink>
      </Box>
    </Box>;
};