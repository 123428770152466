import InfoIcon from '@mui/icons-material/Info';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import { EMAIL_STATUS_RECEIVED, EMAIL_STATUS_OPENED, EMAIL_STATUS_CONVERTED, EMAIL_STATUS_BLOCKED, EMAIL_STATUS_OPENED_MULTIPLE_TIMES, type EmailStatus } from '@/shared/constants/EmailConstants';
type ConversionStatusChipProps = {
  status: EmailStatus;
};
export const ConversionStatusChip = ({
  status
}: ConversionStatusChipProps) => {
  if (!status) {
    return null;
  }
  const getChipProps = () => {
    switch (status) {
      case EMAIL_STATUS_RECEIVED:
        return {
          label: 'Received',
          sx: {
            backgroundColor: 'warning.12p'
          }
        };
      case EMAIL_STATUS_OPENED:
        return {
          label: <Typography color="#784600" fontSize={12}>
              Opened
            </Typography>,
          sx: {
            backgroundColor: 'warning.50p'
          }
        };
      case EMAIL_STATUS_OPENED_MULTIPLE_TIMES:
        return {
          label: <Typography color="#784600" fontSize={12}>
              Opened
            </Typography>,
          icon: <Stack direction="row" alignItems="center">
              <LocalFireDepartmentIcon fontSize="small" color="error" />
              <InfoIcon fontSize="small" sx={{
              color: 'text.primary',
              fontSize: 14
            }} />
            </Stack>,
          sx: {
            backgroundColor: 'warning.50p'
          }
        };
      case EMAIL_STATUS_CONVERTED:
        return {
          label: <Typography color="#00501C" fontSize={12}>
              Converted
            </Typography>,
          sx: {
            backgroundColor: '#AFFFB6'
          }
        };
      case EMAIL_STATUS_BLOCKED:
        return {
          label: <Typography color="#720606" fontSize={12}>
              Blocked
            </Typography>,
          sx: {
            backgroundColor: 'error.50p'
          },
          icons: <InfoIcon fontSize="small" sx={{
            color: 'text.primary'
          }} />
        };
      default:
        return null;
    }
  };
  const getTooltipProps = () => {
    switch (status) {
      case EMAIL_STATUS_BLOCKED:
        return {
          title: `Customer didn’t receive the quote because the email was blocked or bounced. Check if the email address is correct.`
        };
      case EMAIL_STATUS_OPENED_MULTIPLE_TIMES:
        return {
          title: `This quote was opened multiple times.`
        };
      default:
        return null;
    }
  };
  const chipProps = getChipProps();
  const tooltipProps = getTooltipProps();
  if (!chipProps) {
    return null;
  }
  const chip = <Chip {...chipProps} sx={{
    ...chipProps.sx,
    '& .MuiChip-icon': {
      ml: 0,
      mr: 0.5
    },
    flexDirection: 'row-reverse',
    // This height is set to more or less match the height of the QuoteStatus legacy component
    height: 28
  }} variant="filled" />;
  return tooltipProps ? <Tooltip {...tooltipProps} data-sentry-element="Tooltip" data-sentry-component="ConversionStatusChip" data-sentry-source-file="ConversionStatusChip.tsx">{chip}</Tooltip> : chip;
};